require('./style.css');

function component() {
	const element = document.createElement('div');
	element.innerHTML = ['Hello', 'webpack1'].join(' ');
	return element;
}

// const mountApp = document.getElementById('app');
// mountApp.appendChild(component());
